<template>
    <div>
        <div class="w-full lg:w-7/12 mx-auto">
            <h1 class="font-bold mt-4 text-2xl mb-6">Diagnostic</h1>

            <!-- Service Worker Check -->
            <div class="box-style grid grid-cols-1 lg:grid-cols-2 my-6">
                <div class="col-span-2">
                    <div class="flex p-5 gap-x-3 items-center border-b border-gray-400">
                        <div class="flex-1 leading-tight">
                            <h2 class="text-lg font-semibold m-0 capitalize">
                                Service Worker
                                <span v-if="SWisInstalled" class="success-label rounded-md px-2 py-1 mx-1 inline-block font-light text-xs">
                                    Installed
                                </span>
                                <span v-if="!SWisInstalled" class="error-label rounded-md px-2 py-1 mx-1 inline-block font-light text-xs">
                                    Not Installed
                                </span>
                                <span v-if="hasAnUpdate" class="success-label rounded-md px-2 py-1 mx-1 inline-block font-light text-xs">
                                    Update Ready for installation
                                </span>
                            </h2>
                            <span class="normal-label rounded-md px-2 py-1 mt-2 inline-block font-light text-xs">
                                {{ getHostName() }}
                            </span>
                            <div v-if="hasAnUpdate" class="my-3 text-sm leading-relaxed">
                                
                                <p>A new version on the traction app is available. To update, do a hard refresh</p>

                                <h3 class="text-lg my-2 font-bold">Google Chrome</h3>
                                <ul class="text-sm">
                                    <li>Windows users: hold down Ctrl and then press F5 on your keyboard</li>
                                    <li>Mac users: hold down Cmd and Shift and then press R on your keyboard</li>
                                </ul>
                                <h3 class="text-lg my-2 font-bold">Firefox</h3>
                                <ul class="text-sm">
                                    <li>For Windows users: hold down Ctrl and then press F5 on your keyboard</li>
                                    <li>Mac users: hold down ? Cmd and ? Shift and then press R on your keyboard</li>
                                </ul>
                            </div>
                        </div>
                        <!-- <div class="flex-initial border border-gray-800 text-gray-800 p-2 text-sm rounded-lg">
                            Clear Data
                        </div> -->
                    </div>
                </div>
            </div>

            <!-- Offline Manager is Running -->
            <div class="box-style grid grid-cols-1 lg:grid-cols-2 my-6">
                <div class="col-span-2">
                    <div class="flex p-5 gap-x-3 items-center border-b border-gray-400">
                        <div class="flex-1 leading-tight">
                            <h2 class="text-lg font-semibold m-0 capitalize">
                                Offline Manager
                                <span v-if="hasOfflineManagerRunning" class="success-label rounded-md px-2 py-1 mx-3 inline-block font-light text-xs">
                                    Installed
                                </span>
                                <span v-else class="error-label rounded-md px-2 py-1 mx-3 inline-block font-light text-xs">
                                    Not running
                                </span>
                            </h2>
                            <p v-if="hasOfflineManagerRunning" class="my-3 text-xs leading-relaxed">Offline Manager is running. Info Below:</p>
                            <p v-else class="my-3 text-xs leading-relaxed">
                                <span class="text-sm font-semibold">
                                    Offline Manager Not running, and as a result your reocrds aren't being synced.
                                    Here are some things you can try:
                                </span>
                                <ul class="my-3 leading-loose">
                                    <li> - Close your browser entirely. Open it and access the website, coming back to this page</li>
                                    <li> - Try Running your website on the <a target="_blank" class="font-bold underline" href="https://www.google.co.uk/intl/en_uk/chrome/">Google Chrome Browser</a></li>
                                    <li> - If the above two do not work, take a screenshot of this page and call customer support</li>
                                </ul>
                            </p>

                            <template v-if="hasOfflineManagerRunning && offlineDbWasReachable">
                                <div v-for="(module, index) in moduleData" :key="index" class="flex border-t-2 py-4 gap-x-3 items-center">
                                    <div class="flex-1 leading-tight">
                                        <h2 class="text-lg font-semibold m-0 capitalize">{{module.modulename}}</h2>
                                        <span class="border border-gray-800 text-black rounded-md px-2 py-1 mt-2 inline-block font-light text-xs">
                                            {{module.recordCount}} Records Offline
                                        </span>
                                    </div>
                                    <span class="text-black rounded-md px-2 py-1 mt-2 inline-block font-light text-xs">
                                        {{ readableTime(module.lastUpdate) }}
                                    </span>
                                </div>
                            </template>
                            <div v-else class="border border-red-400 bg-red-200 p-3 text-sm rounded-lg">
                                <span class="text-sm font-semibold">
                                    Failed. Could not reach Offline database. Here are some things you can try
                                </span>
                                <ul class="my-3 leading-loose">
                                    <li> - Close your browser entirely. Open it and access the website, coming back to this page</li>
                                    <li> - Try Running your website on the <a target="_blank" class="font-bold underline" href="https://www.google.co.uk/intl/en_uk/chrome/">Google Chrome Browser</a></li>
                                    <li> - If the above two do not work, take a screenshot of this page and call customer support</li>
                                </ul>
                            </div>
                        </div>
                        <!-- <div class="flex-initial border border-gray-800 text-gray-800 p-2 text-sm rounded-lg">
                            Clear Data
                        </div> -->
                    </div>
                </div>
            </div>

            <!-- Business Details -->
            <div class="box-style grid grid-cols-1 lg:grid-cols-2 my-6">
                <div class="col-span-2">
                    <div class="flex p-5 gap-x-3 items-center border-b border-gray-400">
                        <div class="flex-1 leading-tight">
                            <h2 class="text-lg font-semibold m-0 capitalize">
                                Account Summary
                            </h2>
                            <p class="my-3 text-xs leading-relaxed">Details of Logged in user (for customer support reference)</p>
                            
                            <div class="overflow-x-auto relative bg-gray-200 rounded-lg">
                                <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                    <tbody>
                                        <tr class="border-b dark:bg-gray-800 dark:border-gray-700">
                                            <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                Business Name
                                            </th>
                                            <td class="py-4 px-6">
                                                {{ businessData.name || 'N/A' }}
                                            </td>
                                        </tr>
                                        <tr class="border-b dark:bg-gray-800 dark:border-gray-700">
                                            <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                BusinessID
                                            </th>
                                            <td class="py-4 px-6">
                                                {{ businessData.user_id || 'N/A' }}
                                            </td>
                                        </tr>
                                        <tr class="dark:bg-gray-800">
                                            <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                Email
                                            </th>
                                            <td class="py-4 px-6">
                                                {{ businessData.email || 'N/A' }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                        <!-- <div class="flex-initial border border-gray-800 text-gray-800 p-2 text-sm rounded-lg">
                            Clear Data
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { GET_USER_BUSINESS_DATA, GET_USER_BUSINESS_ID } from '../browser-db-config/localStorage'
import { ModuleConfig } from "../offline-module/config";
import AdvancedSearch from '../offline-module/advancedSearch'
import moment from 'moment'
import OfflineManager from '../offline-module/index'
    export default {
        data() {
            return {
                supportsSW: false,
                SWisInstalled: false,
                hasAnUpdate: false,
                hasOfflineManagerRunning: false,
                businessData: {},
                offlineDbWasReachable: true,
                moduleData: []
            }
        },
        async created () {
            const businessData = GET_USER_BUSINESS_DATA()
            this.businessData = businessData
            if ("serviceWorker" in navigator) {
                this.supportsSW = true
                const sw = await navigator.serviceWorker.getRegistration()
                this.SWisInstalled = sw.active ? true : false
                this.hasAnUpdate = sw.installing ? true : false
            } else {
                this.supportsSW = false
            }
            const offlineManager = this.$GlobalOfflineManager
            if (offlineManager instanceof OfflineManager) {
                this.hasOfflineManagerRunning = true
                const offlineFetchAbortController = new AbortController();

                this.moduleData = await this.getLastSyncForAllModules(offlineFetchAbortController).catch((err) => {
                    console.error('Fatal Error getting Meta Data List: ', err)
                    this.offlineDbWasReachable = false
                    this.moduleData = []
                })

                const recordCounts = await this.getRecordCount(offlineFetchAbortController).catch((err) => {
                    console.error('Fatal Error getting Record Count: ', err)
                    this.offlineDbWasReachable = false
                    this.moduleData = []
                })

                recordCounts.forEach((module, index) => {
                    if (this.moduleData[index]) {
                        this.$set(this.moduleData[index], 'recordCount', module.totalRecords)
                    }
                })

                // debugger

                const cancelInThirtySeconds = setTimeout(() => {
                    offlineFetchAbortController.abort();
                    clearTimeout(cancelInThirtySeconds);
                }, 30000);

            } else {
                
            }
        },
        methods: {
            getHostName() {
                return window.location.hostname
            },
            readableTime(timestamp) {
                if (timestamp && !isNaN(timestamp)) {
                    return moment(timestamp*1000).utc().format("LLL");
                } else {
                    return 'Not Applicable'
                }
            },
            async getLastSyncForAllModules(abortSignal = new AbortController()) {
                return new Promise((resolve, reject) => {
                    abortSignal.signal.addEventListener('abort', () => {
                        reject('Could not access offline data in 30 seconds');
                    });
                    const modules = Object.keys(ModuleConfig)
                    Promise.all(modules.map(module => this.$GlobalOfflineManager.getMetaData(module))).then((moduleMetaDataList) => {
                        resolve(moduleMetaDataList)
                    })
                })
            },
            async getRecordCount(abortSignal = new AbortController()) {
                return new Promise((resolve, reject) => {
                    abortSignal.signal.addEventListener('abort', () => {
                        reject('Could not access offline data in 30 seconds');
                    });
                    const adv = new AdvancedSearch({
                        BusinessId: GET_USER_BUSINESS_ID(),
                        offlineManager: this.$GlobalOfflineManager,
                    })
                    const modules = Object.keys(ModuleConfig)
                    Promise.all(modules.map(module => adv.getTotalRecords(module))).then((totalCounts) => {
                        const generated = modules.map((module, index) => {
                            return {
                                name : module,
                                totalRecords: totalCounts[index],
                            }
                        })
                        resolve(generated)
                    })
                })
            }
        },
    }
</script>

<style lang="postcss" scoped>
.normal-label {
    @apply border border-gray-800 text-black
}
.success-label {
    @apply border bg-green-600 text-white
}
.warning-label {
    @apply border border-yellow-600 text-yellow-700
}
.error-label {
    @apply border border-red-600 text-red-700
}
.history-title {
  background: #effaff;
}
.box-style {
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(22, 120, 176, 0.12);
  border-radius: 15px;
}
</style>